import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 16px;
  height: 100%;
  width: 100%;
`;

export const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Frame = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 700px;
  color: rgb(76, 76, 76);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  flex-flow: column;
  margin: 0 auto;
  padding: 16px;
  background: transparent;
  border-radius: 3px;
`;

export const Background = styled.div`
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
  opacity: 1;
  background-size: cover;
  background-color: rgb(230, 27, 114);
`;

export const Wrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
