import React from "react";
import "./App.css";

import { Container, Root, Frame, Background, Wrapper } from "./styles";

function App() {
  return (
    <Container>
      <Root>
        <Frame>
          <Wrapper>
            <div
              className="cui-embed"
              style={{ height: "500px", width: "110%" }}
              data-cui-uid="EgQw8d"
              data-cui-avatar="https://images.typeform.com/images/dDSUSrsJkJXV"
              data-cui-mode="widget"
              data-cui-pill-button-color="#0000FF"
            />
          </Wrapper>
        </Frame>
        <Background />
      </Root>
    </Container>
  );
}

export default App;
